import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`These are my joke projects, don't take them too seriously`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/jokes/obamahypercube"
        }}>{`Obama Hypercube`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/jokes/kill-weezer"
        }}>{`Kill Weezer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/jokes/thanks-for-watching"
        }}>{`Thanks for Watching`}</a>{` `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      